
.video {
    width: 100%;
    max-width: fit-content;
    height: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video img {
    width: 90%;
    align-items: center;
}