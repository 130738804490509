html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

.main {
    background-image: url(../../next/images/background.png);
    background-size: cover;
    min-height: 100vh;
    padding: 0 0.5rem;
    width: 80%;
    max-width: 1920px;
    margin: auto;
}

