
.body {
    display: flex;
    align-items: center;
    margin: 20px;
}

@media (max-width: 1024px) {
    .body {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }