
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px;
    text-align: center;
}

h1 {
    margin-bottom: 0;
    font-weight: 700;


}

p {
    margin-top: 0;
    font-size: larger;
}