
.grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 800px;
    margin-top: 3rem;
  }
  
  .card {
    display: flex;
    margin: 1rem;
    padding: 1.5rem;
    text-align: left;
    text-decoration: none;
    border: 3px solid #eaeaea;
    border-radius: 5px;
    transition: color 0.15s ease, border-color 0.15s ease;
    background: #fafafa;
    align-items: center;
  }
 
  .card:hover,
  .card:focus,
  .card:active {
    border-color: #0070f3;
  }
  .card:hover .title,
  .card:focus .title,
  .card:active .title {
    color: #0070f3;
    text-decoration: underline;
  }
  
  .card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
  }
  
  .card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .image-container {
    width: 45%;
  }

  .logo {
    margin: 40px;
  }

.title:hover,
.title:focus,
.title:active {
  text-decoration: underline;
}

